import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
  {
    path: '/ecosystem',
    name: 'ecosystem',
    component: () => import(/* webpackChunkName: "ecosystem" */ '../views/EcosystemView.vue')
  },

  {
    path: '/validator',
    name: 'validator',
    component: () => import(/* webpackChunkName: "validator" */ '../views/ValidatorView.vue')
  },

  {
    path: '/delegator',
    name: 'delegator',
    component: () => import(/* webpackChunkName: "delegator" */ '../views/DelegatorView.vue')
  },

  {
    path: '/validatorslots',
    name: 'validatorslots',
    component: () => import(/* webpackChunkName: "validatorslots" */ '../views/ValidatorSlots.vue')
  },

  
  {
    path: "/validatorform",
    name: "validatorform",
    component: () =>
      import(
        /* webpackChunkName: "validatorform" */ "../views/ValidatorForm.vue"
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
},  
})

export default router
