import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],

  state: {
    PLAN: "",
    ALL_PLAN:""
  },
  mutations: {
    SET_PLAN(state, payload) {
      state.PLAN = payload
    },
    SET_ALL_PLAN(state, payload) {
      state.ALL_PLAN = payload
    }
  },
  getters: {
    getPLAN: (state) => {
      return state.PLAN
    },
    getALL_PLAN: (state) => {
      return state.ALL_PLAN
    }
  }
})
